@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=MonteCarlo&family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap);
/* font-family: 'Style Script', cursive; */
/* font-family: 'MonteCarlo', cursive; */
/* font-family: 'Dancing Script', cursive; */
/* font-family: 'Quicksand', sans-serif; */
/* font-family: 'Comic Neue', cursive; */
:root
{
  --background : rgb(15, 15, 15);
}
html
{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(15, 15, 15);
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a
{
  text-decoration: none;
}

.visitorCount
{
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  margin: 70px 0;
  flex-direction: row;
}
.visitorCount h3
{
  color: white;
  margin: auto 20px;
  font-size: 1.2rem;
}

.lmTRsh a
{
  display: none !important;
}
.counter, .kROLya
{
  /* transform: scale(0.95); */
  max-width: 100vw !important;
}

@media (max-width:700px)
{
  .visitorCount{
    flex-direction: column;
    margin: 40px 0;
  }
  .visitorCount h3
  {
    margin: 20px auto;
  }
}

.social
{
    margin-top: 40px;
    width: 100vw;
    overflow-x: hidden;
    z-index: inherit;
    z-index: 4;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
}

.social-heading
{   
    padding: 0 5%;
    
}

.social-heading p
{
    letter-spacing: 1px;
    color: grey;
    font-size: 0.9rem;
}
.social-heading h1
{
    font-size: 2.5rem;
    letter-spacing: 2px;
}

.social-list
{
    width: auto;
    justify-self: center;
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    margin: 50px 5% 70px;
}

.social-card
{
    background-color: rgb(37, 37, 37);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.5s;
}
.social-card:hover
{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: 0.5s;

}
.card-top
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 0px 5px;
    padding: 3px 0px;
    
}

.card-top h2
{
    margin: 0;
    /* font-family: 'Dancing Script', cursive; */
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 0px;
    font-size: 1.9rem;
    color: rgb(141, 141, 141);
    font-weight: 700;
}
.card-footer
{
    width: 100%;
    height: 3px;
}

.card-footer-blue
{
    background-image: linear-gradient(to right, rgb(122,252,211) 0%, rgb(101,187,247) 60%);

}

.card-footer-pink
{
    background-image: linear-gradient(to right, rgb(147, 94, 245) 0%, rgb(237,148,204) 70%);

}

.icon
{
    -webkit-transform: scale(4);
            transform: scale(4);
    fill: rgb(158, 158, 158);
    margin: 0px 10px 10px;
    position: relative;
    top: -5px;
    opacity: 0.3;
}
.contact
{
    overflow-y: hidden;
}
.contact-info a{
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin: 10px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    word-spacing: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.contact-info
{
    margin: 30px 5% 50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

span.ya
{
    position: absolute;
    right: -8%;
    top: -40%;
    font-size: 23rem;
    font-weight: 500;
    opacity: 0.1;
    z-index: 2;
}

@media (max-width:822px)
{
    .social-heading
    {   
        padding: 0 10%;
        
    }
    .social-list
    {
        margin: 50px 10% 70px;
    }
    span.ya
    {
        right: -13%;
        top: -35%;
        font-size: 17rem;
    }
    .contact-info
    {
        margin: 30px 5% 50px 10%;
    }
    .contact-info a
    {
        font-size: 1.5rem;
    }
}
@media (max-width:415px)
{
    span.ya
    {
        right: -20%;
        top: -10%;
        font-size: 12rem;
    }
    .contact-info a
    {
        font-size: 1.1rem;
    }
    .social-heading h1
    {
        font-size: 2.3rem;
    }
    .contact-info
    {
        margin: 10px 5% 50px 10%;
    }
    .social-list
    {
        grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
    }
}
@media (max-width:375px)
{
    .social-heading h1
    {
        font-size: 2.1rem;
    }
    .contact-info a
    {
        font-size: 1rem;
    }
    span.ya
    {
        right: -20%;
        top: 5%;
        font-size: 12rem;
    }
    .contact-info
    {
        margin: 0px 5% 50px 10%;
    }
}
@media (max-width:321px)
{
    .contact-info a
    {
        font-size: 0.7rem;
    }
    .social-heading h1
    {
        font-size: 1.5rem;
    }
    .contact-info
    {
        margin: 10px 5% 50px 15%;
    }
    
}
.canvas {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: inset 0px 0px 50px rgba(12, 12, 12, 0.788);
  z-index: 1 !important;
  position: relative;
}

.spline {
  width: 110%;
  position: relative;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  right: 3%;
  border-bottom-right-radius: 10%;
  z-index: 2 !important;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  z-index: 3;
  text-align: left;
}

.header-text p,
.header-text h1 {
  margin: 5px 0;
}

.header-text p {
  font-size: 1.1rem;
  cursor: grab;
}
#highfive {
  font-size: larger;
}
.header-text h1 {
  font-size: 4.7rem;
  transition: 1.4s;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgb(147, 94, 245) 0%,
    rgb(237, 148, 204) 60%
  );
  background-image: url(/static/media/gradient.fab39bb1.gif);
  background-size: 200%;
  background-position: center;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.header-text h1:hover {
  -webkit-text-fill-color: white;
  -moz-text-fill-color: white;
}

p.tagline {
  font-size: 1.3rem;
  margin-top: 10px;
}

@media (max-width: 540px) {
  .spline {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  .header-text h1 {
    font-size: 4rem;
  }
}
@media (max-width: 415px) {
  .spline {
    -webkit-transform: scale(1.18);
            transform: scale(1.18);
    right: 0%;
  }
  .header-text h1 {
    font-size: 3.3rem;
  }
  .header-text p {
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .spline {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    right: 10%;
  }
  .header-text h1 {
    font-size: 3rem;
  }
  .header-text p {
    font-size: 0.9rem;
  }
}
@media (max-width: 321px) {
  .spline {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    right: 0%;
  }
  .header-text h1 {
    font-size: 2.6rem;
  }
  .header-text p {
    font-size: 0.8rem;
  }
}

.projects
{
    margin-top: 6%;
    width: 100vw;
    z-index: 3;
    box-shadow: 0px 0px 45px 100px var(--background) ;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
}

.projects-heading
{   
    padding: 0 5%;
    
}

.projects-heading p
{
    letter-spacing: 1px;
    color: grey;
    font-size: 0.9rem;
}
.projects-heading h1
{
    font-size: 2.5rem;
    letter-spacing: 2px;
}

.project-list
{
    width: auto;
    justify-self: center;
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(350px,1fr));
    margin: 50px 5%;
}

.project{
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    color: black;
    margin: 5px;
}

.blue
{
    background-image: linear-gradient(rgb(122,252,211) 0%, rgb(101,187,247) 100%);
}
.pink
{
    background-image: linear-gradient(rgb(147, 94, 245) 0%, rgb(237,148,204) 100%);
}

.project-wrapper:hover .project
{

    color: white;
    background-color: var(--background);
}


.project-wrapper
{
    position: relative;
}
.border
{
    position: absolute;
    top: 6%;
    right: 6%;
    width: 96%;
    height: 96%;
    border: 3px solid white;
    z-index: -1;
    display: none;
}
.project-wrapper:hover .border
{
    display: block;
}
.project-wrapper:hover .links a
{
    background-color: black;
}

.links
{
    text-align: right;
}
.links a
{
    color: unset;
}

.github
{
    /* fill: black; */
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    margin: 0px 20px;
    margin-right: 0;
    cursor: pointer;
}

.external-link
{
    /* color: black; */
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    margin: 0px 20px;
    margin-right: 0;
    margin-right: 0;
    font-weight: 700;
    cursor: pointer;
}
.behance
{
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    margin: 0px 20px;
    margin-right: 0;
    cursor: pointer;
}

.project-text
{
    position: relative;
    margin: 15px 0 20px;
}
.project-title
{
    font-size: 3.5rem;
    margin: 0;
}
.viewAll
{
    margin: 15px auto 20px;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    border-bottom: 2px dashed grey;
    color: rgb(209, 209, 209);
}
.project-body
{
    margin: 20px 0;
    display: none;
}

.project-wrapper:hover .project-body
{
    display: block;
}
.project-wrapper:hover .project-title
{
    font-size: 3.3rem;
}

.tech-used
{
    text-align: center;
}
.tech-used span
{
    margin: 0px 10px;
    font-weight: 700;
}

@media (min-width:1100px)
{
    .projects
    {
    box-shadow: 0px -50px 45px 100px var(--background) ;

    }
}
@media (min-width:822px) and (max-width:1099px)
{
    .projects
    {
    box-shadow: 0px -20px 45px 100px var(--background) ;

    }
}

@media (max-width:822px)
{
    .project-list
    {
        margin: 50px 10%;
    }
    .projects-heading
    {   
        padding: 0 10%;
        
    }
}
@media (max-width:415px)
{
    .project-list
    {
        grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
    }
    .project{
        min-height: 270px;
    }
    .projects-heading h1
    {
        font-size: 2.3rem;
    }
    .project-title
    {
        font-size: 2.3rem;
        text-align: center;
    }
    .project-wrapper:hover .project-title
    {
        font-size: 2rem;
        text-align: left;
    }
}
@media (max-width:375px)
{
    .project-title
    {
        font-size: 2.3rem;
        text-align: center;
    }
    .project-wrapper:hover .project-title
    {
        font-size: 2rem;
        text-align: left;
    }
    .project-body
    {
        font-size: 0.9rem;
    }
    .projects-heading h1
    {
        font-size: 2.1rem;
    }
    .project-list
    {
        grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
    }
    .project{
        min-height: 250px;
    }
}
@media (max-width:321px)
{
    .project-list
    {
        grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
    }
    .project{
        min-height: 200px;
    }
    .project-list
    {
        margin: 50px 15%;
    }
    .projects-heading h1
    {
        font-size: 1.5rem;
    }
    .projects-heading p
    {
        font-size: 0.7rem;
    }
    .project-title
    {
        font-size: 2.3rem;
        text-align: center;
    }
    .project-wrapper:hover .project-title
    {
        font-size: 2rem;
        text-align: left;
    }
}
