@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=MonteCarlo&family=Style+Script&display=swap');
/* font-family: 'Style Script', cursive; */
/* font-family: 'MonteCarlo', cursive; */
/* font-family: 'Dancing Script', cursive; */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
/* font-family: 'Quicksand', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');
/* font-family: 'Comic Neue', cursive; */
:root
{
  --background : rgb(15, 15, 15);
}
html
{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
  height: fit-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a
{
  text-decoration: none;
}

.visitorCount
{
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  margin: 70px 0;
  flex-direction: row;
}
.visitorCount h3
{
  color: white;
  margin: auto 20px;
  font-size: 1.2rem;
}

.lmTRsh a
{
  display: none !important;
}
.counter, .kROLya
{
  /* transform: scale(0.95); */
  max-width: 100vw !important;
}

@media (max-width:700px)
{
  .visitorCount{
    flex-direction: column;
    margin: 40px 0;
  }
  .visitorCount h3
  {
    margin: 20px auto;
  }
}