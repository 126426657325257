.social
{
    margin-top: 40px;
    width: 100vw;
    overflow-x: hidden;
    z-index: inherit;
    z-index: 4;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
}

.social-heading
{   
    padding: 0 5%;
    
}

.social-heading p
{
    letter-spacing: 1px;
    color: grey;
    font-size: 0.9rem;
}
.social-heading h1
{
    font-size: 2.5rem;
    letter-spacing: 2px;
}

.social-list
{
    width: auto;
    justify-self: center;
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    margin: 50px 5% 70px;
}

.social-card
{
    background-color: rgb(37, 37, 37);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.5s;
}
.social-card:hover
{
    transform: scale(1.05);
    transition: 0.5s;

}
.card-top
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 0px 5px;
    padding: 3px 0px;
    
}

.card-top h2
{
    margin: 0;
    /* font-family: 'Dancing Script', cursive; */
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 0px;
    font-size: 1.9rem;
    color: rgb(141, 141, 141);
    font-weight: 700;
}
.card-footer
{
    width: 100%;
    height: 3px;
}

.card-footer-blue
{
    background-image: linear-gradient(to right, rgb(122,252,211) 0%, rgb(101,187,247) 60%);

}

.card-footer-pink
{
    background-image: linear-gradient(to right, rgb(147, 94, 245) 0%, rgb(237,148,204) 70%);

}

.icon
{
    transform: scale(4);
    fill: rgb(158, 158, 158);
    margin: 0px 10px 10px;
    position: relative;
    top: -5px;
    opacity: 0.3;
}
.contact
{
    overflow-y: hidden;
}
.contact-info a{
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin: 10px 0;
    width: fit-content;
    word-spacing: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.contact-info
{
    margin: 30px 5% 50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

span.ya
{
    position: absolute;
    right: -8%;
    top: -40%;
    font-size: 23rem;
    font-weight: 500;
    opacity: 0.1;
    z-index: 2;
}

@media (max-width:822px)
{
    .social-heading
    {   
        padding: 0 10%;
        
    }
    .social-list
    {
        margin: 50px 10% 70px;
    }
    span.ya
    {
        right: -13%;
        top: -35%;
        font-size: 17rem;
    }
    .contact-info
    {
        margin: 30px 5% 50px 10%;
    }
    .contact-info a
    {
        font-size: 1.5rem;
    }
}
@media (max-width:415px)
{
    span.ya
    {
        right: -20%;
        top: -10%;
        font-size: 12rem;
    }
    .contact-info a
    {
        font-size: 1.1rem;
    }
    .social-heading h1
    {
        font-size: 2.3rem;
    }
    .contact-info
    {
        margin: 10px 5% 50px 10%;
    }
    .social-list
    {
        grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
    }
}
@media (max-width:375px)
{
    .social-heading h1
    {
        font-size: 2.1rem;
    }
    .contact-info a
    {
        font-size: 1rem;
    }
    span.ya
    {
        right: -20%;
        top: 5%;
        font-size: 12rem;
    }
    .contact-info
    {
        margin: 0px 5% 50px 10%;
    }
}
@media (max-width:321px)
{
    .contact-info a
    {
        font-size: 0.7rem;
    }
    .social-heading h1
    {
        font-size: 1.5rem;
    }
    .contact-info
    {
        margin: 10px 5% 50px 15%;
    }
    
}