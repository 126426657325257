.canvas {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: inset 0px 0px 50px rgba(12, 12, 12, 0.788);
  z-index: 1 !important;
  position: relative;
}

.spline {
  width: 110%;
  position: relative;
  transform: scale(1.4);
  right: 3%;
  border-bottom-right-radius: 10%;
  z-index: 2 !important;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 3;
  text-align: left;
}

.header-text p,
.header-text h1 {
  margin: 5px 0;
}

.header-text p {
  font-size: 1.1rem;
  cursor: grab;
}
#highfive {
  font-size: larger;
}
.header-text h1 {
  font-size: 4.7rem;
  transition: 1.4s;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgb(147, 94, 245) 0%,
    rgb(237, 148, 204) 60%
  );
  background-image: url("../media/gradient.gif");
  background-size: 200%;
  background-position: center;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.header-text h1:hover {
  -webkit-text-fill-color: white;
  -moz-text-fill-color: white;
}

p.tagline {
  font-size: 1.3rem;
  margin-top: 10px;
}

@media (max-width: 540px) {
  .spline {
    transform: scale(1.3);
  }
  .header-text h1 {
    font-size: 4rem;
  }
}
@media (max-width: 415px) {
  .spline {
    transform: scale(1.18);
    right: 0%;
  }
  .header-text h1 {
    font-size: 3.3rem;
  }
  .header-text p {
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .spline {
    transform: scale(1.15);
    right: 10%;
  }
  .header-text h1 {
    font-size: 3rem;
  }
  .header-text p {
    font-size: 0.9rem;
  }
}
@media (max-width: 321px) {
  .spline {
    transform: scale(1.05);
    right: 0%;
  }
  .header-text h1 {
    font-size: 2.6rem;
  }
  .header-text p {
    font-size: 0.8rem;
  }
}
