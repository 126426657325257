.projects
{
    margin-top: 6%;
    width: 100vw;
    z-index: 3;
    box-shadow: 0px 0px 45px 100px var(--background) ;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
}

.projects-heading
{   
    padding: 0 5%;
    
}

.projects-heading p
{
    letter-spacing: 1px;
    color: grey;
    font-size: 0.9rem;
}
.projects-heading h1
{
    font-size: 2.5rem;
    letter-spacing: 2px;
}

.project-list
{
    width: auto;
    justify-self: center;
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(350px,1fr));
    margin: 50px 5%;
}

.project{
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    color: black;
    margin: 5px;
}

.blue
{
    background-image: linear-gradient(rgb(122,252,211) 0%, rgb(101,187,247) 100%);
}
.pink
{
    background-image: linear-gradient(rgb(147, 94, 245) 0%, rgb(237,148,204) 100%);
}

.project-wrapper:hover .project
{

    color: white;
    background-color: var(--background);
}


.project-wrapper
{
    position: relative;
}
.border
{
    position: absolute;
    top: 6%;
    right: 6%;
    width: 96%;
    height: 96%;
    border: 3px solid white;
    z-index: -1;
    display: none;
}
.project-wrapper:hover .border
{
    display: block;
}
.project-wrapper:hover .links a
{
    background-color: black;
}

.links
{
    text-align: right;
}
.links a
{
    color: unset;
}

.github
{
    /* fill: black; */
    transform: scale(1.3);
    margin: 0px 20px;
    margin-right: 0;
    cursor: pointer;
}

.external-link
{
    /* color: black; */
    transform: scale(1.4);
    margin: 0px 20px;
    margin-right: 0;
    margin-right: 0;
    font-weight: 700;
    cursor: pointer;
}
.behance
{
    transform: scale(1.4);
    margin: 0px 20px;
    margin-right: 0;
    cursor: pointer;
}

.project-text
{
    position: relative;
    margin: 15px 0 20px;
}
.project-title
{
    font-size: 3.5rem;
    margin: 0;
}
.viewAll
{
    margin: 15px auto 20px;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    border-bottom: 2px dashed grey;
    color: rgb(209, 209, 209);
}
.project-body
{
    margin: 20px 0;
    display: none;
}

.project-wrapper:hover .project-body
{
    display: block;
}
.project-wrapper:hover .project-title
{
    font-size: 3.3rem;
}

.tech-used
{
    text-align: center;
}
.tech-used span
{
    margin: 0px 10px;
    font-weight: 700;
}

@media (min-width:1100px)
{
    .projects
    {
    box-shadow: 0px -50px 45px 100px var(--background) ;

    }
}
@media (min-width:822px) and (max-width:1099px)
{
    .projects
    {
    box-shadow: 0px -20px 45px 100px var(--background) ;

    }
}

@media (max-width:822px)
{
    .project-list
    {
        margin: 50px 10%;
    }
    .projects-heading
    {   
        padding: 0 10%;
        
    }
}
@media (max-width:415px)
{
    .project-list
    {
        grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
    }
    .project{
        min-height: 270px;
    }
    .projects-heading h1
    {
        font-size: 2.3rem;
    }
    .project-title
    {
        font-size: 2.3rem;
        text-align: center;
    }
    .project-wrapper:hover .project-title
    {
        font-size: 2rem;
        text-align: left;
    }
}
@media (max-width:375px)
{
    .project-title
    {
        font-size: 2.3rem;
        text-align: center;
    }
    .project-wrapper:hover .project-title
    {
        font-size: 2rem;
        text-align: left;
    }
    .project-body
    {
        font-size: 0.9rem;
    }
    .projects-heading h1
    {
        font-size: 2.1rem;
    }
    .project-list
    {
        grid-template-columns: repeat(auto-fit, minmax(280px,1fr));
    }
    .project{
        min-height: 250px;
    }
}
@media (max-width:321px)
{
    .project-list
    {
        grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
    }
    .project{
        min-height: 200px;
    }
    .project-list
    {
        margin: 50px 15%;
    }
    .projects-heading h1
    {
        font-size: 1.5rem;
    }
    .projects-heading p
    {
        font-size: 0.7rem;
    }
    .project-title
    {
        font-size: 2.3rem;
        text-align: center;
    }
    .project-wrapper:hover .project-title
    {
        font-size: 2rem;
        text-align: left;
    }
}